/* eslint-disable no-async-promise-executor */
import AV from "leancloud-storage";

export default {
  init: () => {
    AV.init({
      appId: "Ma381X2ea6AFT7lACXiObr7T-gzGzoHsz",
      appKey: "h8SouM1NTUWuzEz8GN51pjpq",
      serverURL: "https://talktoomuch.api.hearfresh.cn"
    });
  },
  getCurrentUser: () => AV.User.current(),
  setNickName: async nickName => {
    try {
      const user = AV.User.current();
      await user.set("nickName", nickName).save();
      console.log("setNickName success", user);
      return user;
    } catch (error) {
      console.log("setNickName failure", error);
      throw error;
    }
  },
  login: async () => {
    try {
      const user = await AV.User.loginWithMiniApp();
      console.log("login success", user);
      return user;
    } catch (error) {
      console.log("login failure", error);
      throw error;
    }
  },
  setUserInfo: async userInfo => {
    const user = AV.User.current();
    await user
      .set(userInfo)
      .save()
      .catch(error => {
        console.log("setUserInfoToServer failure", error);
        throw error;
      });
    console.log("setUserInfoToServer success", user);
  },
  /**
   * 发送验证码
   * @remark 通用函数
   * @param phoneNumber 手机号
   * @returns { undefined | Error }
   */
  sendSmsVerifyCode: phoneNumber =>
    new Promise(async (resolve, reject) => {
      try {
        await AV.Cloud.requestSmsCode(phoneNumber);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),
  /**
   * 使用用户名、验证码登录
   */
  loginWithVerificationCode: (phoneNumber, verificationCode) =>
    new Promise(async (resolve, reject) => {
      try {
        const user = await AV.User.signUpOrlogInWithMobilePhone(
          phoneNumber,
          verificationCode
        );
        resolve(user);
      } catch (error) {
        reject(error);
      }
    })
};
