import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Api from "./api/index.js";

Api.init();

createApp(App)
  .use(router)
  .mount("#app");
