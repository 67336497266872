<template>
  <view class="topbar-container">
    <Logo style="margin-left:4.93vw;"></Logo>
    <ShareWechat style="margin-left: 4.67vw;"></ShareWechat>
  </view>
</template>

<script>
import Logo from "./Logo.vue";
import ShareWechat from "./ShareWechat.vue";

export default {
  components: { Logo, ShareWechat }
};
</script>

<style lang="stylus" scoped>
.topbar-container {
  width 100vw
  display flex
  align-items center
  margin-top 4.85vh
}
</style>
