<template>
  <div class="room-container">
    <div class="title">❤️ 北京大型相亲 ROOM，欢迎单身的你来聊天！！</div>
    <div class="user">刘孟军</div>
    <div class="user">刘孟军</div>
    <div class="user">刘孟军</div>
    <div style="height:2.55vh;"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.room-container {
  width 88.53vw
  background rgba(255, 255, 255, 0.85)
  box-shadow 0px 0.22vh 2.62vh rgba(0, 0, 0, 0.16)
  border-radius 0.67vh
  display flex
  flex-direction column
  align-items flex-start
}

.title {
  margin-left 4.93vw
  margin-right 4.93vw
  margin-top 2.55vh
  margin-bottom 1.35vh
  font-size 1.8vh
  font-family PingFang SC
  font-weight 600
  color #1B1D2E
  opacity 1
}

.user {
  margin-left 4.93vw
  font-size 1.8vh
  font-family PingFang SC
  font-weight 400
  color #1B1D2E
  opacity 1
  margin-bottom 0.6vh
}
</style>
