<template>
  <view class="logo-container">
    <view class="top-container">
      <svg
        class="logo-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="39.977"
        height="34.666"
        viewBox="0 0 39.977 34.666"
      >
        <g id="组_1183" data-name="组 1183" transform="translate(-67.1 -125.6)">
          <path
            id="路径_296"
            data-name="路径 296"
            d="M426.513,145.934c0-2.877-1.918-.076-1.918-5.315v-2.353a.469.469,0,0,0-.466-.466h-1.645a.469.469,0,0,0-.466.466v2.353c0,5.239-1.918,2.438-1.918,5.315v7.48a.7.7,0,0,0,.7.7h5.015a.7.7,0,0,0,.7-.7Z"
            transform="translate(-337.179 -11.653)"
            fill="#3fc5d1"
          />
          <path
            id="路径_297"
            data-name="路径 297"
            d="M462.8,154.1h2.581v1.013H462.8Z"
            transform="translate(-377.966 -27.223)"
          />
          <path
            id="路径_298"
            data-name="路径 298"
            d="M452.3,318.6h3.523v6.409H452.3Z"
            transform="translate(-367.936 -184.35)"
            fill="#fff"
          />
          <path
            id="路径_299"
            data-name="路径 299"
            d="M457.1,126.837a.428.428,0,0,1-.426.426h-2.55a.428.428,0,0,1-.426-.426v-.811a.428.428,0,0,1,.426-.426h2.55a.428.428,0,0,1,.426.426Z"
            transform="translate(-369.273)"
            fill="#a4a9ad"
          />
          <path
            id="路径_300"
            data-name="路径 300"
            d="M212.813,170.434c0-2.877-1.918-.076-1.918-5.315v-2.353a.469.469,0,0,0-.466-.466h-1.645a.469.469,0,0,0-.466.466v2.353c0,5.239-1.918,2.438-1.918,5.315v7.48a.7.7,0,0,0,.7.7h5.015a.7.7,0,0,0,.7-.7Z"
            transform="translate(-133.057 -35.055)"
            fill="#3fc5d1"
          />
          <path
            id="路径_301"
            data-name="路径 301"
            d="M249.1,178.6h2.581v1.013H249.1Z"
            transform="translate(-173.843 -50.625)"
          />
          <path
            id="路径_302"
            data-name="路径 302"
            d="M238.6,343h3.523v6.409H238.6Z"
            transform="translate(-163.814 -207.657)"
            fill="#fff"
          />
          <path
            id="路径_303"
            data-name="路径 303"
            d="M243.3,151.337a.428.428,0,0,1-.426.426h-2.55a.428.428,0,0,1-.426-.426v-.811a.428.428,0,0,1,.426-.426h2.55a.428.428,0,0,1,.426.426Z"
            transform="translate(-165.055 -23.402)"
            fill="#a4a9ad"
          />
          <path
            id="路径_304"
            data-name="路径 304"
            d="M112.7,441h35.895v19.37H112.7Z"
            transform="translate(-43.556 -301.264)"
            fill="#3fc5d1"
          />
          <path
            id="路径_305"
            data-name="路径 305"
            d="M112.7,486.833h3.792v1.824H112.7Zm10.94-13.194h3.792v1.824H123.64Zm3.742,11.294h3.792v1.824h-3.792Zm8.681-3.693h3.792v1.824h-3.792Zm7.13,4.8h3.792v1.824h-3.792Zm-5.75-14.14h3.792v1.824h-3.792Zm9.25,5.159h1.9v1.824h-1.9Zm-33.994.627h1.9v1.824h-1.9Z"
            transform="translate(-43.556 -330.78)"
          />
          <path
            id="路径_306"
            data-name="路径 306"
            d="M660.8,318.6h8.242v4.3H660.8Z"
            transform="translate(-567.092 -184.35)"
            fill="#a4a9ad"
          />
          <path
            id="路径_307"
            data-name="路径 307"
            d="M660.8,318.6h8.242v1.627H660.8Z"
            transform="translate(-567.092 -184.35)"
          />
          <path
            id="路径_308"
            data-name="路径 308"
            d="M656.752,281.339a.44.44,0,0,0-.439-.439h-8.574a.44.44,0,0,0-.439.439v1.2a.44.44,0,0,0,.439.439h8.578a.44.44,0,0,0,.439-.439Z"
            transform="translate(-554.197 -148.34)"
            fill="#d1d3d3"
          />
          <path
            id="路径_309"
            data-name="路径 309"
            d="M148.59,396.013v-3.639a.878.878,0,0,0-.874-.874H113.574a.878.878,0,0,0-.874.874v3.639Z"
            transform="translate(-43.556 -253.983)"
            fill="#333e48"
          />
          <path
            id="路径_310"
            data-name="路径 310"
            d="M676.3,568.9h8.242v13.634H676.3Z"
            transform="translate(-581.897 -423.432)"
            fill="#fa8f31"
          />
          <path
            id="路径_311"
            data-name="路径 311"
            d="M707.794,721.695m-.695,0a.695.695,0,1,0,.695-.695A.695.695,0,0,0,707.1,721.695Z"
            transform="translate(-611.317 -568.716)"
            fill="#333e48"
          />
          <path
            id="路径_312"
            data-name="路径 312"
            d="M172.874,559.869A.878.878,0,0,1,172,559v-8.722a.878.878,0,0,1,.874-.874H190.72a.878.878,0,0,1,.874.874V559a.878.878,0,0,1-.874.874Z"
            transform="translate(-100.199 -404.806)"
            fill="#fff"
          />
          <path
            id="路径_313"
            data-name="路径 313"
            d="M211,595.374V588.4l4.025,6.974Zm4.025,0,4.025-6.974,4.025,6.974Z"
            transform="translate(-137.451 -442.058)"
            fill="#fa8f31"
          />
          <path
            id="路径_314"
            data-name="路径 314"
            d="M219.049,588.4l-4.025,6.974L211,588.4Z"
            transform="translate(-137.451 -442.058)"
            fill="#f4ea2a"
          />
          <path
            id="路径_315"
            data-name="路径 315"
            d="M398.649,588.4l-4.025,6.974L390.6,588.4Z"
            transform="translate(-309.001 -442.058)"
            fill="#f4ea2a"
          />
          <path
            id="路径_316"
            data-name="路径 316"
            d="M480.4,595.374l4.025-6.974v6.974Z"
            transform="translate(-394.777 -442.058)"
            fill="#fa8f31"
          />
          <path
            id="路径_317"
            data-name="路径 317"
            d="M107.077,857.778a.348.348,0,0,1-.345.345H67.445a.348.348,0,0,1-.345-.345v-1.233a.348.348,0,0,1,.345-.345h39.287a.348.348,0,0,1,.345.345Z"
            transform="translate(0 -697.856)"
            fill="#333e48"
          />
          <path
            id="路径_318"
            data-name="路径 318"
            d="M655.4,856.2h10.12v1.923H655.4Z"
            transform="translate(-561.934 -697.856)"
            fill="#a4a9ad"
          />
        </g>
      </svg>

      <view class="title">话 痨 酒 吧</view>
    </view>
    <view class="description">hackathon project by shaolizhi</view>
  </view>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.logo-container {
  display flex
  flex-direction column
  align-items flex-start
}

.top-container {
  display flex
  align-items center
  height 2.85vh
}

.logo-icon {
  width 5.33vw
  height 2.6vh
}

.title {
  font-size 2.02vh
  margin-left 2.35vw
  font-weight 600
  color #222a36
}

.description {
  font-size 1.57vh
  font-family PingFang SC
  font-weight 400
  line-height 2.17vh
  color #222a36
  opacity 0.5
  margin-top 0.67vh
}
</style>
