<template>
  <view class="login-container" @click="clickEvent">
    <!-- <image
      class="avatar"
      :src="currentUser.avatarUrl"
      v-if="hasUserInfo"
    ></image> -->

    <!-- <view class="user-container" v-if="hasUserInfo">
      <view class="nickName">
        {{ currentUser.nickName }}
      </view>

      <view class="description">
        {{ currentUser.country + " " + currentUser.province }}
      </view>
    </view> -->
    <!-- <svg
      class="login-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="32.051"
      height="26.128"
      viewBox="0 0 32.051 26.128"
    >
      <g
        id="组_1205"
        data-name="组 1205"
        transform="translate(-25.941 -120.491)"
      >
        <path
          id="路径_328"
          data-name="路径 328"
          d="M47.639,128.4a8.809,8.809,0,0,1,1.093.068c-.98-4.567-5.866-7.973-11.447-7.973-6.242,0-11.344,4.248-11.344,9.647,0,3.121,1.7,5.672,4.544,7.665l-1.139,3.417,3.964-1.993a19.229,19.229,0,0,0,3.975.569c.353,0,.706-.023,1.059-.046a8.655,8.655,0,0,1-.353-2.38C37.98,132.427,42.24,128.4,47.639,128.4Zm-6.1-3.075a1.418,1.418,0,1,1,0,2.836,1.443,1.443,0,1,1,0-2.836Zm-7.95,2.836a1.443,1.443,0,1,1,0-2.836,1.418,1.418,0,0,1,0,2.836Z"
          fill="#22c4a4"
        />
        <path
          id="路径_329"
          data-name="路径 329"
          d="M427.859,384.043c0-4.533-4.544-8.235-9.636-8.235-5.4,0-9.647,3.7-9.647,8.235s4.248,8.235,9.647,8.235a14.3,14.3,0,0,0,3.406-.569l3.109,1.708-.854-2.836C426.162,388.872,427.859,386.605,427.859,384.043Zm-12.768-1.424a1.139,1.139,0,1,1,0-2.278,1.167,1.167,0,1,1,0,2.278Zm6.242,0a1.139,1.139,0,0,1,0-2.278,1.167,1.167,0,1,1,0,2.278Z"
          transform="translate(-369.867 -246.798)"
          fill="#22c4a4"
        />
      </g>
    </svg> -->

    <svg
      class="login-icon"
      t="1613579330132"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3849"
      width="200"
      height="200"
    >
      <path
        d="M512 613.4c-89.3 0-162.3-73.1-162.3-162.3V201.2h324.7v249.9c-0.1 89.3-73.1 162.3-162.4 162.3z"
        fill="#FFFFFF"
        p-id="3850"
      ></path>
      <path
        d="M512 622.8c-94.7 0-171.7-77-171.7-171.7V201.2c0-5.2 4.2-9.4 9.4-9.4h324.7c5.2 0 9.4 4.2 9.4 9.4v249.9c-0.1 94.7-77.1 171.7-171.8 171.7zM359 210.6v240.5c0 84.3 68.6 152.9 153 152.9s153-68.6 153-152.9V210.6H359z"
        fill="#603813"
        p-id="3851"
      ></path>
      <path
        d="M512 613.4c-89.3 0-162.3-73.1-162.3-162.3v-80.4s35.3-25.8 88.3-25.8S518.4 384 577.7 384c59.3 0 96.6-13.4 96.6-13.4V451c0 89.4-73 162.4-162.3 162.4z"
        fill="#F9C933"
        p-id="3852"
      ></path>
      <path
        d="M512 622.8c-94.7 0-171.7-77-171.7-171.7v-80.5c0-3 1.4-5.8 3.9-7.6 1.5-1.1 38.5-27.6 93.8-27.6 27.8 0 49.1 10 69.7 19.6 21.4 10 41.7 19.5 70.1 19.5 56.7 0 93.1-12.7 93.5-12.8 2.9-1 6.1-0.6 8.6 1.2 2.5 1.8 4 4.6 4 7.7v80.5c-0.2 94.7-77.2 171.7-171.9 171.7zM359 375.7v75.4c0 84.3 68.6 152.9 153 152.9s153-68.6 153-152.9v-67.9c-16 4.1-46.6 10.2-87.3 10.2-32.6 0-55.7-10.8-78-21.3-19.6-9.2-38.1-17.9-61.7-17.9-39.7 0-68.9 15.4-79 21.5z"
        fill="#603813"
        p-id="3853"
      ></path>
      <path
        d="M512 831.7c-5.2 0-9.4-4.2-9.4-9.4V613.4c0-5.2 4.2-9.4 9.4-9.4s9.4 4.2 9.4 9.4v208.9c0 5.2-4.2 9.4-9.4 9.4z"
        fill="#603813"
        p-id="3854"
      ></path>
      <path
        d="M640.5 831.7h-257c-5.2 0-9.4-4.2-9.4-9.4s4.2-9.4 9.4-9.4h256.9c5.2 0 9.4 4.2 9.4 9.4s-4.2 9.4-9.3 9.4z"
        fill="#603813"
        p-id="3855"
      ></path>
    </svg>

    <view class="login-tip">
      {{ nickNameForUI ? nickNameForUI : `点击登录` }}
    </view>

    <svg
      class="login-right"
      xmlns="http://www.w3.org/2000/svg"
      width="9.006"
      height="16.501"
      viewBox="0 0 9.006 16.501"
    >
      <g
        id="组_1203"
        data-name="组 1203"
        transform="translate(283.945 -70.887) rotate(90)"
      >
        <path
          id="路径_323"
          data-name="路径 323"
          d="M477.328,283.938l-8.246-8.249.75-.75,8.246,8.246Z"
          transform="translate(-390.688)"
          fill="#222a36"
        />
        <path
          id="路径_324"
          data-name="路径 324"
          d="M79.885,276.2l-8.246,8.246-.751-.75,8.247-8.246Z"
          transform="translate(0 -0.497)"
          fill="#222a36"
        />
      </g>
    </svg>

    <!-- 小程序登录用 -->
    <!-- <button
      open-type="getUserInfo"
      @getuserinfo="userInfoHandler"
      style="height:100%;width:100%;opacity: 0;position: absolute;"
    ></button> -->
  </view>
</template>

<script>
import Api from "../api/index.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
// import Store from "../store/index.js";
export default {
  setup() {
    const nickNameForUI = ref("");
    const router = useRouter();
    const clickEvent = () => {
      viewModel.click();
    };

    const viewModel = {
      click: async () => {
        if (Api.getCurrentUser() === null) {
          router.push("/login");
          return;
        }

        let nickName = Api.getCurrentUser().attributes.nickName;
        nickName = prompt("请输入你的昵称", nickName);
        console.log(nickName);
        if (nickName !== null) {
          await Api.setNickName(nickName);
          nickNameForUI.value = nickName;
        }
      }
    };

    if (Api.getCurrentUser() !== null) {
      nickNameForUI.value = Api.getCurrentUser().attributes.nickName;
    }

    return {
      nickNameForUI,
      clickEvent
    };
    // const hasUserInfo = ref(false);
    // const currentUser = inject(Store.currentUser, ref(null));
    // const viewModel = async (hasUserInfo, currentUser) => {
    //   // 查看是否拥有 UserInfo 权限
    //   try {
    //     currentUser.value = await Api.login();
    //     console.log("currentUser", currentUser.value);
    //     await Api.hasUserInfoAuth();
    //     hasUserInfo.value = true;
    //     const userInfo = await Api.getUserInfo();
    //     await Api.setUserInfo(userInfo);
    //   } catch (error) {
    //     console.log(error);
    //     hasUserInfo.value = false;
    //   }
    // };
    // // 用户点击授权登录函数
    // const userInfoHandler = res => {
    //   const userInfo = res.target.userInfo;
    //   console.log(userInfo);
    //   const saveUserInfo = async () => {
    //     hasUserInfo.value = true;
    //     await Api.setUserInfo(userInfo);
    //   };
    //   saveUserInfo();
    // };
    // viewModel(hasUserInfo, currentUser);
    // return {
    //   userInfoHandler,
    //   hasUserInfo,
    //   currentUser
    // };
  }
};
</script>

<style lang="stylus" scoped>
.login-container {
  width 88.53vw
  height 10.04vh
  background rgba(255, 255, 255, 0.85)
  box-shadow 0px 0.22vh 2.62vh rgba(0, 0, 0, 0.16)
  border-radius 0.67vh
  display flex
  align-items center
  position relative
}

.login-icon {
  width 4.27vw
  height 1.96vh
  margin-left 4.97vw
  margin-right 5.29vw
}

.login-tip {
  font-size 2.02vh
  font-family PingFang SC
  font-weight 600
  color #222a36
  opacity 1
}

.login-right {
  right 5.33vw
  top 0
  bottom 0
  margin-top auto
  margin-bottom auto
  width 2.4vw
  height 1vh
  position absolute
}

.avatar {
  width 4.57vh
  height 4.57vh
  background rgba(0, 0, 0, 0)
  border-radius 50%
  opacity 1
  margin-left 4.93vw
}

.user-container {
  display flex
  flex-direction column
  align-items flex-start
  margin-left 2.93vw
}

.nickName {
  height 2.47vh
  font-size 1.8vh
  font-family PingFang SC
  font-weight 400
  color #1b1d2e
  opacity 1
  margin-bottom 0.75vh
}

.description {
  height 1.95vh
  font-size 1.42vh
  font-family PingFang SC
  font-weight 100
  color #1b1d2e
  opacity 1
}
</style>
