<template>
  <view class="share-container">
    <svg
      class="wechat-icon"
      id="组_1206"
      data-name="组 1206"
      xmlns="http://www.w3.org/2000/svg"
      width="38.564"
      height="38.564"
      viewBox="0 0 38.564 38.564"
    >
      <path
        id="路径_330"
        data-name="路径 330"
        d="M319.488,352.97a1.253,1.253,0,1,0,1.253-1.253A1.251,1.251,0,0,0,319.488,352.97Zm5.63-.039a1.28,1.28,0,0,0,1.253,1.273,1.267,1.267,0,0,0,1.273-1.253,1.251,1.251,0,0,0-1.253-1.253A1.227,1.227,0,0,0,325.118,352.932Zm3.162,5.65a.868.868,0,1,0,.868-.868A.852.852,0,0,0,328.281,358.581Zm4.454.058a.868.868,0,1,0,.868-.868A.864.864,0,0,0,332.735,358.639Z"
        transform="translate(-307.456 -338.451)"
        fill="#50b674"
      />
      <path
        id="路径_331"
        data-name="路径 331"
        d="M19.282,0A19.282,19.282,0,1,0,38.564,19.282,19.264,19.264,0,0,0,19.282,0Zm-3.3,23.736a12.917,12.917,0,0,1-2.834-.424l-2.834,1.408.81-2.41a6.585,6.585,0,0,1-3.22-5.476c0-3.856,3.644-6.864,8.1-6.864,3.953,0,7.462,2.41,8.175,5.688a5.168,5.168,0,0,0-.771-.039,6.612,6.612,0,0,0-6.864,6.421,5.876,5.876,0,0,0,.251,1.678C16.486,23.7,16.235,23.736,15.985,23.736ZM27.843,26.57l.6,2.025L26.223,27.38a9.748,9.748,0,0,1-2.41.424c-3.856,0-6.864-2.622-6.864-5.862s3.008-5.862,6.864-5.862c3.644,0,6.864,2.622,6.864,5.862a6.081,6.081,0,0,1-2.834,4.628Z"
        fill="#5dc656"
      />
    </svg>

    <view class="right-container">
      <view class="share-cn">分享 App</view>
      <view class="share-en">SHARE APP</view>
    </view>
  </view>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.share-container {
  height 4.42vh
  background #ffffff
  box-shadow 0px 0.22vh 2.62vh rgba(0, 0, 0, 0.16)
  opacity 1
  border-radius 2.25vh
  display flex
  align-items center
}

.wechat-icon {
  width 2.92vh
  height 2.92vh
  margin-right 3.25vw
  margin-left 2.13vw
}

.right-container {
  display flex
  flex-direction column
  align-items center
  margin-right 4.93vw
}

.share-cn {
  height 1.87vh
  font-size 1.35vh
  font-family PingFang SC
  font-weight 600
  color #222a36
  opacity 1
}

.share-en {
  height 1.5vh
  font-size 1.05vh
  font-family PingFang SC
  font-weight 100
  color #222a36
  opacity 1
}
</style>
